import React, { useState } from "react";
import { Link } from "react-router-dom";

import { BounceLoaderPlum, ShowPasswordButton } from "../../components";
import { register } from "../../services/authService";
import { createCard } from "../../services/feedbackService";
import useLocalState from "../../utils/localState";

import LogoPlum from "../../assets/images/logo-plum.png";
import "./auth.css";

export default function Register() {
	const [isSent, setIsSent] = useState(false);

	const { alert, showAlert, loading, setLoading, hideAlert } = useLocalState();

	const [showPassword, setShowPassword] = useState(false);
	const [showRepeatPassword, setShowRepeatPassword] = useState(false);

	const [formData, setFormData] = useState({
		email: "",
		password: "",
		repeatPassword: "",
	});

	const changeHandler = (e) => {
		setFormData((oldData) => ({
			...oldData,
			[e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
		}));
	};

	const submitHandler = async (e) => {
		e.preventDefault();

		if (!formData.email || !formData.password | !formData.repeatPassword) {
			showAlert({ text: "All fields are required" });
			setTimeout(() => {
				hideAlert();
			}, 5000);
			return;
		}

		if (formData.password !== formData.repeatPassword) {
			showAlert({ text: "Passwords do not match" });
			return;
		}

		hideAlert();
		setLoading(true);

		try {
			const response = await register(formData.email, formData.password);

			let userEmail = formData.email;
			setFormData({ email: "", password: "", repeatPassword: "" });

			if (response.status === 201) {
				await createCard("NEW REGISTRATION", `${userEmail} registered - ${new Date().toLocaleString()}`);
				setIsSent(true);
			} else {
				const message = await response.json();
				showAlert({ text: message.msg });
			}
		} catch (error) {
			showAlert({ text: "Something went wrong. Try again later" });
		} finally {
			setLoading(false);
		}
	};

	const handleShowPassword = (e) => {
		setShowPassword(!showPassword);
	};

	const handleShowRepeatPassword = (e) => {
		setShowRepeatPassword(!showRepeatPassword);
	};

	return (
		<>
			<BounceLoaderPlum isLoading={loading} />

			{alert.show && <div className="message-container">{alert.text}</div>}

			<div id="register">
				<div className="auth-left">
					<a href="/">
						<div className="auth-logo">
							<img src={LogoPlum} alt="auth-logo" />
						</div>
						<h2 className="auth-title">Innovation Flightdeck</h2>
					</a>
				</div>

				<div className="auth-right">
					{!isSent ? (
						<div className="auth-form-container">
							<h1 className="text-plum">Sign up</h1>
							<h2>and start your journey</h2>
							<div className="separator"></div>

							<p>
								You already have an account?{" "}
								<b>
									<span className="text-plum">
										<Link to="/auth/login" className="link">
											Log in instead!
										</Link>
									</span>
								</b>
							</p>

							<form className="form" onSubmit={submitHandler}>
								<div>
									<label htmlFor="email">
										<b>Email</b>
									</label>
									<input
										id="email"
										type="email"
										placeholder="johndoe@mail.com"
										name="email"
										value={formData.email}
										onChange={changeHandler}
										className="auth-input"
										required
									/>
								</div>

								<div className="relative">
									<label htmlFor="password">
										<b>Password</b>
									</label>
									<input
										id="password"
										type={showPassword ? "text" : "password"}
										placeholder="* * * * * *"
										name="password"
										value={formData.password}
										onChange={changeHandler}
										className="auth-input"
										required
									/>
									<ShowPasswordButton showPassword={showPassword} onClick={handleShowPassword} />
								</div>

								<div className="relative">
									<label htmlFor="password">
										<b>Confirm Password</b>
									</label>
									<input
										id="repeatPassword"
										type={showRepeatPassword ? "text" : "password"}
										placeholder="* * * * * *"
										name="repeatPassword"
										value={formData.repeatPassword}
										onChange={changeHandler}
										className="auth-input"
										required
									/>
									<ShowPasswordButton showPassword={showRepeatPassword} onClick={handleShowRepeatPassword} />
								</div>

								<button className="auth-btn" type="submit">
									Sign up
								</button>
							</form>
						</div>
					) : (
						<div className="auth-form-container">
							<h1 className="text-plum">Email verification link</h1>
							<h2>was sent to your email address</h2>
							<div className="separator"></div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
