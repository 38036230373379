import { Link } from "react-router-dom";

// External Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faLightbulb } from "@fortawesome/free-solid-svg-icons";

// Context
import { useAuthContext } from "../../contexts/AuthContext";

// Components
import { Navigation, Feedback } from "../../components";

// Styles
import "./home.css";
import HomepageImg from "../../assets/images/homepage.svg";

// Main Home Component
export default function Home() {
	const { user, isDemoUser } = useAuthContext();

	return (
		<>
			<Navigation />

			<div className="app-container">
				{user && !isDemoUser ? <UserContent /> : <HeroSection />}

				<Feedback />
			</div>
		</>
	);
}

// Component for authenticated users
function UserContent() {
	return (
		<>
			<Link to="/inspiration-cards/" className="app-card">
				<h2>Inspiration Cards</h2>
				<FontAwesomeIcon icon={faLightbulb} className="app-icon" />
			</Link>

			<Link to="/innovation-flight/" className="app-card">
				<h2>Innovation Flightdeck</h2>
				<FontAwesomeIcon icon={faPaperPlane} className="app-icon" />
			</Link>
		</>
	);
}

// Component for users who are not authenticated
function HeroSection() {
	return (
		<div className="hero-section">
			<div className="hero-text">
				<h1>
					The <span className="text-dark">Innovation Flightdeck</span> is the high-flyer for your innovation challenge.
				</h1>
				<p>
					In just a few minutes, you'll move from problem to initial solution sketches – perfect for individuals and teams looking to
					quickly explore and pave the way for a successful innovation project.
				</p>
				<Link to="/innovation-flight-demo" className="btn btn-dark" style={{ marginTop: "0.5rem", display: "inline-block" }}>
					Try Now For Free
				</Link>
			</div>

			<img src={HomepageImg} alt="Design Thinking Hero" className="hero-img" />
		</div>
	);
}
