import { useState } from "react";

import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSave } from "@fortawesome/free-solid-svg-icons";

export const EditableField = ({ index, fieldValue, selectedFieldValue, onSave, isEditing, onEdit, handleSelect }) => {
	const [newValue, setNewValue] = useState(fieldValue);

	const handleInputChange = (e) => {
		setNewValue(e.target.value);
	};

	return (
		<div style={{ display: "flex", width: "100%", gap: "0.5rem", alignItems: "center" }}>
			<div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
				{isEditing ? (
					<>
						<textarea
							value={newValue}
							onChange={handleInputChange}
							style={{
								fontSize: "1rem",
								padding: "0.2rem 0.5rem",
								border: "1px solid var(--main-color-dark)",
								borderRadius: "5px",
								background: "transparent",
								outline: "none",
								margin: "1rem 0rem",
								lineHeight: "1.5",
								flexGrow: 1,
								width: "100%",
								resize: "none",
								minHeight: "3rem",
							}}
						/>
					</>
				) : (
					<>
						<span
							className={`selectable-title ${selectedFieldValue === fieldValue ? "selected-selectable-title" : ""}`}
							onClick={() => handleSelect(fieldValue)}>
							{fieldValue}
						</span>
					</>
				)}
			</div>

			{isEditing ? (
				<button
					onClick={() => {
						onSave(newValue);
					}}
					className="control-btn">
					<FontAwesomeIcon
						icon={faSave}
						style={{ outline: "none" }}
						data-tooltip-id={`${index}-save-tooltip`}
						data-tooltip-content="Save"
					/>
					<Tooltip id={`${index}-save-tooltip`} />
				</button>
			) : (
				<button onClick={onEdit} className="control-btn">
					<FontAwesomeIcon icon={faPen} style={{ outline: "none" }} data-tooltip-id={`${index}-edit-tooltip`} data-tooltip-content="Edit" />
					<Tooltip id={`${index}-edit-tooltip`} />
				</button>
			)}
		</div>
	);
};
