import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function ShowPasswordButton({ showPassword, onClick }) {
	return (
		<FontAwesomeIcon
			icon={showPassword ? faEyeSlash : faEye}
			className="absolute"
			style={{
				top: "55px",
				right: "15px",
				color: "var(--main-color-dark)",
				fontSize: "1.5rem",
				cursor: "pointer",
			}}
			onClick={onClick}
		/>
	);
}
