import { useFlightContext } from "../../contexts/FlightContext";
import { TabSection } from "..";
import { useIntersectionAnimation } from "../../hooks";

import "./tabs.css";

export default function JobToBeDone() {
	const { currentVersion, prevTabIndex, activeTabIndex } = useFlightContext();

	// Determine the animation class based on the tab index
	const animationClass = prevTabIndex < activeTabIndex ? "slide-in-from-right" : "slide-in-from-left";

	// Call the useIntersectionAnimation hook within the component
	useIntersectionAnimation(".tab-container", animationClass);

	return (
		<div className="tab-container">
			{currentVersion.selectedTouchpoint ? (
				<div style={{ marginBottom: "2rem" }}>
					<h2 className="tab-title">Selected Touchpoint</h2>
					<p>{`Title: ${Object.values(currentVersion.selectedTouchpoint)[1].title}`}</p>
					<p>{`Thoughts: ${Object.values(currentVersion.selectedTouchpoint)[1].thoughts}`}</p>
					<p>{`Actions: ${Object.values(currentVersion.selectedTouchpoint)[1].actions}`}</p>
					<p>{`Satisfaction Level: ${Object.values(currentVersion.selectedTouchpoint)[1].satisfactionLevel}`}</p>
				</div>
			) : (
				<TabSection title={"Touchpoint not selected"} content={"Select touchpoint first"} />
			)}

			<TabSection title="Job to be Done" content={currentVersion.jobToBeDone} />

			<TabSection title="Pains & Gains" content={currentVersion.painsAndGains} />
		</div>
	);
}
