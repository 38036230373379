import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "react-tooltip";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { logout } from "../../services/authService";
import { useAuthContext } from "../../contexts/AuthContext";
import useLocalState from "../../utils/localState";
import { useSpeechSynthesisVoice, useSpeechSynthesisPitch, useSpeechSynthesisRate } from "../../hooks";

import { updateUserPassword, getUserCount } from "../../services/authService";
import { getAllTestUserFlights, deleteAllTestUserFlights } from "../../services/flightService";
import { BounceLoaderPlum, ShowPasswordButton, Feedback, TextToSpeech, Alert } from "../../components";

import LogoPlum from "../../assets/images/logo-plum.png";
import "./auth.css";

export default function Profile() {
	const navigate = useNavigate();

	const { user } = useAuthContext();
	const { voice, setVoice } = useSpeechSynthesisVoice();
	const { rate, setRate } = useSpeechSynthesisRate();
	const { pitch, setPitch } = useSpeechSynthesisPitch();

	const { alert, showAlert, loading, setLoading, hideAlert } = useLocalState();

	const [showPassword, setShowPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);

	const isAdmin = user?.role === "admin";
	const [userCount, setUserCount] = useState({
		totalUserCount: 0,
		activeUserCount: 0,
		productiveUserCount: 0,
	});
	const [testUserFlights, setTestUserFlights] = useState({
		count: 0,
		flights: [],
	});

	const [formData, setFormData] = useState({
		currentPassword: "",
		newPassword: "",
		confirmNewPassword: "",
	});

	const changeHandler = (e) => {
		setFormData((oldData) => ({
			...oldData,
			[e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
		}));
	};

	const submitHandler = async (e) => {
		e.preventDefault();

		if (formData.currentPassword === "" || formData.newPassword === "" || formData.confirmNewPassword === "") {
			showAlert({ text: "All fields are required" });
			return;
		}

		if (formData.newPassword !== formData.confirmNewPassword) {
			showAlert({ text: "New Password and Confirm New Password do not match" });
			return;
		}

		hideAlert();
		setLoading(true);

		try {
			const response = await updateUserPassword(formData.currentPassword, formData.newPassword);

			setFormData({ currentPassword: "", newPassword: "", confirmNewPassword: "" });

			if (response.status === 200) {
				const message = await response.json();

				showAlert({ text: `${message.msg}. You will be logged out now.`, type: "success-message" });

				setTimeout(async () => {
					hideAlert();

					await logout();
					navigate("/auth/login");
				}, 5000);
			} else {
				const message = await response.json();
				showAlert({ text: message.msg });
			}

			setLoading(false);
		} catch (error) {
			showAlert({ text: "Something went wrong. Try again later" });
			setLoading(false);
		}
	};

	const handleShowPassword = (e) => {
		setShowPassword(!showPassword);
	};

	const handleShowNewPassword = (e) => {
		setShowNewPassword(!showNewPassword);
	};

	const handleShowRepeatNewPassword = (e) => {
		setShowRepeatNewPassword(!showRepeatNewPassword);
	};

	// HANDLE VOICE CHANGE
	const handleVoiceChange = (event) => {
		const voices = window.speechSynthesis.getVoices();
		const selectedVoice = voices.find((v) => v.name === event.target.value);
		setVoice(selectedVoice);

		localStorage.setItem("selectedVoice", selectedVoice.name);
	};

	// HANDLE RATE CHANGE
	const handleRateChange = (event) => {
		setRate(event.target.value);

		localStorage.setItem("selectedRate", event.target.value);
	};

	const handlePitchChange = (event) => {
		setPitch(event.target.value);

		localStorage.setItem("selectedPitch", event.target.value);
	};

	const handleDeleteTestUserFlights = async () => {
		const confirm = window.confirm("Are you sure you want to delete all test user account flights?");

		if (confirm) {
			try {
				const response = await deleteAllTestUserFlights();

				if (!response.ok) {
					throw new Error("Failed to delete test user account flights");
				}

				const data = await response.json();

				console.log(data);

				showAlert({ text: data.message, type: "success-message" });

				setTestUserFlights({
					count: 0,
					flights: [],
				});
			} catch (error) {
				showAlert({ text: "Something went wrong. Try again later" });
			}
		}
	};

	useEffect(() => {
		if (isAdmin) {
			const fetchData = async () => {
				try {
					const [response1, response2] = await Promise.all([getUserCount(), getAllTestUserFlights()]);

					if (!response1.ok || !response2.ok) {
						throw new Error("Failed to fetch data");
					}

					const data1 = await response1.json();
					const data2 = await response2.json();

					setUserCount({
						totalUserCount: data1.userCount || 0,
						activeUserCount: data1.activeUserCount || 0,
						productiveUserCount: data1.productiveUserCount || 0,
					});

					setTestUserFlights({
						count: data2.count || 0,
						flights: data2.flights || [],
					});
				} catch (error) {
					console.log(error);
				}
			};

			fetchData();
		}
	}, [user, isAdmin]);

	return (
		<>
			<BounceLoaderPlum isLoading={loading} />

			<Alert alert={alert} />

			<div id="profile">
				<div className="auth-left">
					<a href="/">
						<div className="auth-logo">
							<img src={LogoPlum} alt="auth-logo" />
						</div>
						<h2 className="auth-title">Innovation Flightdeck</h2>
					</a>

					<div>
						<div style={{ textAlign: "left", marginBottom: "2rem" }}>
							<div>
								<b>User: </b>
								{user?.email}
							</div>
							<div>
								<b>Role: </b>
								{user?.role}
							</div>

							{isAdmin && (
								<div style={{ marginTop: "1rem" }}>
									<div>
										<b>Total Registered Users: </b>
										{userCount.totalUserCount}
									</div>
									<div>
										<b
											style={{ cursor: "pointer" }}
											data-tooltip-id="info-tooltip"
											data-tooltip-content="Active users are users who have logged in at least once during the last 30 days.">
											Active Users:{" "}
										</b>
										{userCount.activeUserCount}

										<Tooltip id="info-tooltip" />
									</div>
									<div>
										<b
											style={{ cursor: "pointer" }}
											data-tooltip-id="info-tooltip"
											data-tooltip-content="Productive users are users who have created at least one new Design Flight during the last 30 days.">
											Productive Users:{" "}
										</b>
										{userCount.productiveUserCount}

										<Tooltip id="info-tooltip" />
									</div>
									<div style={{ marginTop: "1rem" }}>
										<b
											style={{ cursor: "pointer" }}
											data-tooltip-id="test-user-tooltip"
											data-tooltip-content="Total count of test user account flights.">
											Test User Flight Count:{" "}
										</b>
										{testUserFlights.count}

										<Tooltip id="test-user-tooltip" />
									</div>
									<button className="btn btn-light" style={{ marginTop: "0.5rem" }} onClick={handleDeleteTestUserFlights}>
										Clear Test User Flights
									</button>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="auth-right">
					<Tabs>
						<TabList
							style={{
								position: "fixed",
								top: "0",
								right: "33%",
								backgroundColor: "transparent",
								border: "none",
								zIndex: "100",
							}}>
							<Tab style={{ border: "none", borderRadius: "0" }}>Change Password</Tab>
							<Tab style={{ border: "none", borderRadius: "0" }}>Voice Settings</Tab>
						</TabList>

						<TabPanel>
							<div className="profile">
								<form className="form" onSubmit={submitHandler}>
									<div className="relative">
										<label htmlFor="currentPassword">
											<b>Current Password</b>
										</label>
										<input
											type={showPassword ? "text" : "password"}
											placeholder="* * * * * *"
											name="currentPassword"
											value={formData.currentPassword}
											onChange={changeHandler}
											className="auth-input"
											required
										/>
										<ShowPasswordButton showPassword={showPassword} onClick={handleShowPassword} />
									</div>

									<div className="relative">
										<label htmlFor="newPassword">
											<b>New Password</b>
										</label>
										<input
											type={showNewPassword ? "text" : "password"}
											placeholder="* * * * * *"
											name="newPassword"
											value={formData.newPassword}
											onChange={changeHandler}
											className="auth-input"
											required
										/>
										<ShowPasswordButton showPassword={showNewPassword} onClick={handleShowNewPassword} />
									</div>

									<div className="relative">
										<label htmlFor="confirmNewPassword">
											<b>Confirm Password</b>
										</label>
										<input
											type={showRepeatNewPassword ? "text" : "password"}
											placeholder="* * * * * *"
											name="confirmNewPassword"
											value={formData.confirmNewPassword}
											onChange={changeHandler}
											className="auth-input"
											required
										/>
										<ShowPasswordButton showPassword={showRepeatNewPassword} onClick={handleShowRepeatNewPassword} />
									</div>

									<button className="auth-btn" type="submit">
										Update password
									</button>
								</form>
							</div>
						</TabPanel>

						<TabPanel>
							<div className="profile">
								<div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
									<label htmlFor="voice-select">
										<b>Select voice:</b>
									</label>
									<select
										value={voice?.name}
										style={{
											width: "100%",
											border: "1px solid gray",
											borderRadius: "1rem",
											margin: "0.5rem 0rem 1.5rem 0rem",
											padding: "0.5rem 0.75rem",
										}}
										onChange={handleVoiceChange}>
										{window.speechSynthesis
											.getVoices()
											.filter((voice) => voice.lang.startsWith("en"))
											.map((voice) => (
												<option key={voice.name} value={voice.name}>
													{voice.name}
												</option>
											))}
									</select>
								</div>

								<div>
									<label htmlFor="rate-set">
										<b>Set speed:</b>
									</label>
									<input
										type="range"
										min="0.5"
										max="2"
										step="0.1"
										value={rate}
										style={{
											width: "100%",
											border: "1px solid gray",
											borderRadius: "1rem",
											margin: "0.5rem 0rem 1.5rem 0rem",
											padding: "0.5rem 0.75rem",
										}}
										onChange={handleRateChange}
									/>
								</div>

								<div>
									<label htmlFor="rate-set">
										<b>Set pitch:</b>
									</label>
									<input
										type="range"
										min="0.5"
										max="2"
										step="0.1"
										value={pitch}
										style={{
											width: "100%",
											border: "1px solid gray",
											borderRadius: "1rem",
											margin: "0.5rem 0rem 1.5rem 0rem",
											padding: "0.5rem 0.75rem",
										}}
										onChange={handlePitchChange}
									/>
								</div>

								<div>
									<label htmlFor="voice-select">
										<b>Test voice:</b>
									</label>
									<TextToSpeech
										id="profile"
										text="Hello! You've arrived at your profile page. Let's explore and personalize your experience."
									/>
								</div>
							</div>
						</TabPanel>
					</Tabs>

					<Feedback />
				</div>
			</div>
		</>
	);
}
