import React from "react";
import BounceLoader from "react-spinners/BounceLoader";

export default function BounceLoaderPlum({ isLoading, message }) {
	return (
		<div className={isLoading ? "loading-mask" : ""}>
			<BounceLoader color={"var(--main-color-dark)"} loading={isLoading} size={50} aria-label="Loading..." data-testid="loader" />

			{isLoading && <div className="loading-text">{message}</div>}
		</div>
	);
}
