import { useFlightContext } from "../../contexts/FlightContext";
import { TabSection } from "..";
import { useIntersectionAnimation } from "../../hooks";

import "./tabs.css";

export default function MainProblems() {
	const { currentVersion, prevTabIndex, activeTabIndex } = useFlightContext();

	// Determine the animation class based on the tab index
	const animationClass = prevTabIndex < activeTabIndex ? "slide-in-from-right" : "slide-in-from-left";

	// Call the useIntersectionAnimation hook within the component
	useIntersectionAnimation(".tab-container", animationClass);

	return (
		<div className="tab-container">
			<TabSection title="Main Problems" content={currentVersion.mainProblems} />
		</div>
	);
}
