import jsPDF from "jspdf";
import "jspdf-autotable";
import DOMPurify from "dompurify";

export const updateTabsByName = (prevTabs, tabNames, newEnabledValue) => {
	return prevTabs.map((tab) => {
		if (tabNames.includes(tab.title)) {
			return { ...tab, enabled: newEnabledValue };
		}
		return tab;
	});
};

export const checkCondition = (conditions) => {
	return conditions.every(Boolean);
};

export const formatSection = (title, content) => ({
	title,
	content: `${content}`,
});

// Helper to format the personas section
export const formatPersonas = (personas) =>
	Object.keys(personas)
		.map((persona) => `${personas[persona].name}\nNeed: ${personas[persona].need}\nInsight: ${personas[persona].insight}\n`)
		.join("\n");

// Helper to format the user journey section
export const formatUserJourney = (selectedUserJourney, selectedPersonaName) =>
	`${selectedPersonaName}\n\n${Object.entries(selectedUserJourney)
		.map(
			([key, value], index) =>
				`Touchpoint ${index + 1}: ${value.title}\nThoughts: ${value.thoughts}\nActions: ${value.actions}\nSatisfaction Level: ${
					value.satisfactionLevel
				}\n`
		)
		.join("\n")}`;

export const decodeHtmlEntities = (str) => {
	const txt = document.createElement("textarea");
	txt.innerHTML = str;
	return txt.value;
};

export const formatContent = (content) => {
	return content.replace(/\n/g, "<br>");
};

export const formatPDFContent = (content) => {
	return content.replace(/<br\s*\/?>/gi, "\n").replace(/<[^>]*>/g, "");
};

export const cleanUpHtml = (html) => {
	return html.replace(/(<p>)+/g, "<p>").replace(/<\/p>(<\/p>)+/g, "</p>");
};

export const createMarkup = (html) => {
	return { __html: DOMPurify.sanitize(html) };
};

// Generate a random ID
export const randomId = () => {
	return Math.random().toString(36).substring(2, 15);
};
