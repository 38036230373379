import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { verifyEmail } from "../../services/authService";

import { BounceLoaderPlum } from "../../components";
import useLocalState from "../../utils/localState";

import LogoPlum from "../../assets/images/logo-plum.png";
import "./auth.css";

export default function ForgotPassword() {
	const { search } = useLocation();

	const params = new URLSearchParams(search);
	const token = params.get("token");
	const email = params.get("email");

	const [isSent, setIsSent] = useState(false);
	const { alert, showAlert, loading, setLoading, hideAlert } = useLocalState();

	useEffect(() => {
		const fetchData = async () => {
			hideAlert();
			setLoading(true);

			try {
				const response = await verifyEmail(token, email);

				if (response.status === 200) {
					setLoading(false);
					setIsSent(true);
				} else {
					const message = await response.json();
					showAlert({ text: message.msg });
				}

				setLoading(false);
			} catch (error) {
				showAlert({ text: "Something went wrong. Try again later" });
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	return (
		<>
			<BounceLoaderPlum isLoading={loading} />

			{alert.show && <div className="message-container">{alert.text}</div>}

			<div id="login">
				<div className="auth-left">
					<a href="/">
						<div className="auth-logo">
							<img src={LogoPlum} alt="auth-logo" />
						</div>
						<h2 className="auth-title">Innovation Flightdeck</h2>
					</a>
				</div>

				<div className="auth-right">
					{isSent && (
						<div className="auth-form-container">
							<h1 className="text-plum">Account confirmed</h1>
							<h2>you can login into your account now</h2>
							<div className="separator"></div>

							<Link to="/auth/login">
								<button className="auth-btn" type="submit">
									Login
								</button>
							</Link>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
