import { Link } from "react-router-dom";

import NotFoundImg from "../../assets/images/not-found.svg";
import "./not-found.css";

export default function NotFound() {
	return (
		<div className="container">
			<img src={NotFoundImg} alt="not-found-img" className="not-found-img" />

			<p className="not-found-text">Hey! Looks like you are heading to a wrong place!</p>

			<Link
				to={`/`}
				className="btn btn-dark"
				style={{
					marginTop: "0.5rem",
					display: "inline-block",
				}}>
				Take me back to the homepage
			</Link>
		</div>
	);
}
