import { useState } from "react";

import { useFlightContext } from "../../contexts/FlightContext";
import { Regenerate, SmallLoader, SolidLine, TextToSpeech } from "..";
import BounceLoaderPlum from "../UI/Loader/BounceLoaderPlum";
import { askGPT, updateVersion } from "../../services/flightService";
import { randomId } from "../../utils/helpers";
import { Error } from "../../pages";
import { SCENARIO_TITLES } from "../../assets/feedData";
import { useIntersectionAnimation } from "../../hooks";
import "./tabs.css";

const ChallengeItem = ({ challenge, isSelected, onClick }) => (
	<div className="selectable-titles-container">
		<div className={`selectable-title ${isSelected ? "selected-selectable-title" : ""}`} onClick={onClick}>
			<p>{challenge}</p>
		</div>

		<TextToSpeech id={challenge} text={challenge} />
	</div>
);

export default function Challenges() {
	const id = randomId();

	const { currentVersion, selectedChallenge, updateSelectedChallenge, setCurrentVersion, prevTabIndex, activeTabIndex, setActiveTabIndex } =
		useFlightContext();

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);

	const handleClick = async function (challenge) {
		console.log("Generating Abstract and Inspirations...");

		try {
			setIsLoading(true);
			updateSelectedChallenge(challenge);

			// Clear the current version by resetting relevant fields
			let clearedVersion = await clearCurrentVersion(currentVersion, challenge);
			setCurrentVersion(clearedVersion);

			// Generate abstract of the user challenge
			const userChallengeAbstract = await generateAbstract(challenge);

			// Generate inspiration texts in parallel for all scenarios
			const scenarioTexts = await generateInspirationTexts(userChallengeAbstract);

			// Update the current version with the abstract and inspiration texts
			const updatedVersion = await updateVersion(currentVersion._id, {
				versionNumber: currentVersion.versionNumber,
				userChallenge: challenge,
				userChallengeAbstract: userChallengeAbstract,
				inspirationTexts: scenarioTexts,
				ideas: [],
				selectedIdea: "",
				conceptPoster: "",
			});
			setCurrentVersion(updatedVersion);

			setIsLoading(false);
			setActiveTabIndex(8);
		} catch (error) {
			setError(error);
		}
	};

	// Determine the animation class based on the tab index
	const animationClass = prevTabIndex < activeTabIndex ? "slide-in-from-right" : "slide-in-from-left";

	// Call the useIntersectionAnimation hook within the component
	useIntersectionAnimation(".tab-container", animationClass);

	if (error) {
		return <Error error={error} />;
	}

	return (
		<>
			<BounceLoaderPlum isLoading={isLoading} message={"Generating Abstract and Inspirations..."} />

			{!currentVersion.userChallengesJSON && <BounceLoaderPlum isLoading={true} message={"Generating Challenges..."} />}

			<div className="tab-container">
				<h2 className="tab-title">Challenges</h2>

				{currentVersion.userChallengesJSON && (
					<>
						{Object.keys(currentVersion.userChallengesJSON).map((challengeKey, index) => {
							const challenge = currentVersion.userChallengesJSON[challengeKey];
							return (
								<ChallengeItem
									key={index}
									challenge={challenge}
									isSelected={selectedChallenge === challenge}
									onClick={() => handleClick(challenge)}
								/>
							);
						})}

						<SolidLine isDark={true} />

						<Regenerate id={id} title={"Challenges"} />
					</>
				)}
			</div>
		</>
	);
}

// Helper function to clear current version
const clearCurrentVersion = async (currentVersion, challenge) => {
	return await updateVersion(currentVersion._id, {
		...currentVersion,
		userChallenge: challenge,
		userChallengeAbstract: "",
		inspirationTexts: {},
		ideas: [],
		selectedIdea: "",
		conceptPoster: "",
	});
};

// Helper function to generate abstract using GPT
const generateAbstract = async (challenge) => {
	return await askGPT(
		[],
		`Reformulate the following question "${challenge}" by generalizing subject, objects, and activities while keeping all adjectives.`
	);
};

// Helper function to generate inspiration texts for all scenarios
const generateInspirationTexts = async (userChallengeAbstract) => {
	const scenarioPrompts = Object.values(SCENARIO_TITLES).map(
		(title) => `Give an example from "${title}" where the following question has been solved`
	);

	const scenarioResponses = await Promise.all(
		scenarioPrompts.map((scenarioPrompt) =>
			askGPT([], `${scenarioPrompt}: ${userChallengeAbstract}. Keep answer under 800 characters.`).catch((e) => {
				console.error(e);
				return "";
			})
		)
	);

	return scenarioResponses.reduce((texts, response, index) => {
		const key = Object.keys(SCENARIO_TITLES)[index];
		texts[key] = response || "";
		return texts;
	}, {});
};
