const rootUrl = process.env.REACT_APP_ROOT_URL;

export const getAllFlights = async (page = 1, limit = 10) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights?page=${page}&limit=${limit}`, {
			method: "GET",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
		});

		const data = await response.json();

		if (!response.ok) {
			let message = "An error occurred...";

			if (data?.message) {
				message = data.message;
			}

			return { error: true, message, status: response.status };
		}

		return data;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const getAllTestUserFlights = async () => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights/test-user`, {
			method: "GET",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
		});

		return response;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const deleteAllTestUserFlights = async () => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights/test-user`, {
			method: "DELETE",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
		});

		return response;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const getVersionById = async (id) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights/version/${id}`, {
			method: "GET",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
		});

		const data = await response.json();

		if (!response.ok) {
			let message = "An error occurred...";

			if (data?.message) {
				message = data.message;
			}

			return { error: true, message, status: response.status };
		}

		return data;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const getSingleFlight = async (id) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights/${id}`, {
			method: "GET",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
		});

		const data = await response.json();

		if (!response.ok) {
			let message = "An error occured...";

			if (data?.message) {
				message = data.message;
			}

			return { error: true, message, status: response.status };
		}

		return data;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const getShareData = async (id) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights/share/${id}`, {
			method: "GET",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
		});

		const data = await response.json();

		if (!response.ok) {
			let message = "An error occured...";

			if (data?.message) {
				message = data.message;
			}

			return { error: true, message, status: response.status };
		}

		return data;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const createFlight = async (id) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
		});

		const data = await response.json();

		if (!response.ok) {
			let message = "An error occured...";

			if (data?.message) {
				message = data.message;
			}

			return { error: true, message, status: response.status };
		}

		return data;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const updateVersion = async (id, info) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights/version/${id}`, {
			method: "PATCH",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify(info),
		});

		const data = await response.json();

		if (!response.ok) {
			let message = "An error occured...";

			if (data?.message) {
				message = data.message;
			}

			return { error: true, message, status: response.status };
		}

		return data;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const updateFlight = async (id, info) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights/${id}`, {
			method: "PATCH",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify(info),
		});

		const data = await response.json();

		if (!response.ok) {
			let message = "An error occured...";

			if (data?.message) {
				message = data.message;
			}

			return { error: true, message, status: response.status };
		}

		return data;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const deleteFlight = async (id) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights/${id}`, {
			method: "DELETE",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
		});

		const data = await response.json();

		if (!response.ok) {
			let message = "An error occured...";

			if (data?.message) {
				message = data.message;
			}

			return { error: true, message, status: response.status };
		}

		return data;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const createVersionAndAddToFlight = async (id, versionData) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights/${id}/version`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({ versionData }),
		});

		const data = await response.json();

		if (!response.ok) {
			let message = "An error occured...";

			if (data?.message) {
				message = data.message;
			}

			return { error: true, message, status: response.status };
		}

		return data;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const askGPT = async (history, prompt) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights/ask-gpt`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({ history, prompt }),
		});

		const data = await response.json();

		if (!response.ok) {
			let message = "An error occured...";

			if (data?.message) {
				message = data.message;
			}

			return { error: true, message, status: response.status };
		}

		return data;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};

export const generateImage = async (prompt, model, size) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/flights/generate-image`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({ prompt, model, size }),
		});

		const data = await response.json();

		if (!response.ok) {
			let message = "An error occured...";

			if (data?.message) {
				message = data.message;
			}

			return { error: true, message, status: response.status };
		}

		return data;
	} catch (e) {
		console.log(e);
		return { error: true, e };
	}
};
