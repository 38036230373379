import { useState, useEffect } from "react";

// Context
import { useAuthContext } from "../../contexts/AuthContext";
import { useFlightContext } from "../../contexts/FlightContext";

// Components
import FlightList from "./FlightList";
import ActiveFlight from "./ActiveFlight";
import {
	Navigation,
	Questionnaire,
	DesignChallenge,
	MainProblems,
	GoodPractices,
	Personas,
	UserJourney,
	ShareLinkModal,
	JobToBeDone,
	Challenges,
	Inspirations,
	BounceLoaderPlum,
	Alert,
	Ideation,
	ConceptPoster,
} from "../../components/";

// Utils
import useLocalState from "../../utils/localState";
import { TAB_NAMES } from "../../assets/feedData";
import { checkCondition, updateTabsByName } from "../../utils/helpers";

// Styles
import "./design-flight.css";
import { useDemoFlightAutoAdd } from "../../hooks";

export default function DesignFlight() {
	const { isDemoUser } = useAuthContext();
	const {
		currentVersion,
		userFlights,
		loadingUserFlights,
		userFlightsError,
		currentFlight,
		addNewFlight,
		deleteSingleFlight,
		setActiveTabIndex,

		isUserJourneyLoading,
		activeTabIndex,
		hasSubmittedAnswers,
		hasSubmittedSummary,
		hasSubmittedDesignChallenge,
		hasSubmittedMainProblems,
		hasSubmittedGoodPractices,
		hasSubmittedPointsOfView,
		hasSubmittedUserJourney,
	} = useFlightContext();

	const { alert, showAlert, loading, setLoading, hideAlert } = useLocalState();

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalContent, setModalContent] = useState(null);

	const [tabs, setTabs] = useState([
		{
			title: TAB_NAMES.START,
			content: <Questionnaire />,
			enabled: true,
		},
		{
			title: TAB_NAMES.DESIGN_CHALLENGE,
			content: <DesignChallenge />,
			enabled: false,
		},
		{
			title: TAB_NAMES.MAIN_PROBLEMS,
			content: <MainProblems />,
			enabled: false,
		},
		{
			title: TAB_NAMES.GOOD_PRACTICES,
			content: <GoodPractices />,
			enabled: false,
		},
		{
			title: TAB_NAMES.PERSONAS,
			content: <Personas />,
			enabled: false,
		},
		{
			title: TAB_NAMES.USER_JOURNEY,
			content: <UserJourney />,
			enabled: false,
		},
		{
			title: TAB_NAMES.JOB_TO_BE_DONE,
			content: <JobToBeDone />,
			enabled: false,
		},
		{
			title: TAB_NAMES.CHALLENGES,
			content: <Challenges />,
			enabled: false,
		},
		{
			title: TAB_NAMES.IDEATION,
			content: <Ideation />,
			enabled: false,
		},
		{
			title: TAB_NAMES.CONCEPT_POSTER,
			content: <ConceptPoster />,
			enabled: false,
		},
	]);

	const handleAddNew = async (e) => {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

		hideAlert();
		setLoading(true);

		try {
			const response = await addNewFlight();

			if (response.error) {
				showAlert({ text: response.message });
			}

			showAlert({ text: "New Flight successfully created.", type: "success" });
		} catch (error) {
			showAlert({ text: "Something went wrong. Try again later." });
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async (flightId) => {
		const deleteConfirmed = window.confirm("Are you sure you want to delete this Design Flight?");

		if (deleteConfirmed) {
			hideAlert();
			setLoading(true);

			try {
				const response = await deleteSingleFlight(flightId);

				if (response.error) {
					showAlert({ text: response.message });
				}

				showAlert({ text: "Flight successfully deleted.", type: "success" });
				setActiveTabIndex(0);
			} catch (error) {
				showAlert({ text: "Something went wrong. Try again later." });
			} finally {
				setLoading(false);
			}
		}
	};

	const handleOpenModal = async (id) => {
		setIsModalVisible(true);
		setModalContent(id);
	};

	const handleCloseModal = () => {
		setIsModalVisible(false);
	};

	// Manage loading state and handle errors during user flights fetching
	useEffect(() => {
		if (userFlightsError) {
			showAlert({ text: userFlightsError.message });
		}

		if (loadingUserFlights) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [userFlightsError, loadingUserFlights]);

	// Update tabs based on the current flight data
	useEffect(() => {
		if (!currentFlight) return;
		if (!currentVersion) return;

		// Check if the user has submitted the answers
		const firstCondition = checkCondition([currentVersion?.answers]);

		// Update the tabs based on the first condition
		setTabs((prevTabs) =>
			updateTabsByName(prevTabs, [TAB_NAMES.DESIGN_CHALLENGE, TAB_NAMES.MAIN_PROBLEMS, TAB_NAMES.GOOD_PRACTICES], firstCondition)
		);

		// Check if current flight has summary, design challenge, main problems and good practices
		const secondCondition = checkCondition([
			currentVersion.summary,
			currentVersion.designChallenge,
			currentVersion.mainProblems,
			currentVersion.goodPractices,
		]);

		// Update the tabs based on the second condition
		setTabs((prevTabs) => updateTabsByName(prevTabs, [TAB_NAMES.PERSONAS], secondCondition));

		// Check if current flight has summary, design challenge, main problems, good practices, personas, selected persona and user journey is not on load
		const thirdCondition = checkCondition([
			currentVersion.summary,
			currentVersion.designChallenge,
			currentVersion.mainProblems,
			currentVersion.goodPractices,
			currentVersion.personas,
			currentVersion.selectedPersona,
			!isUserJourneyLoading,
		]);

		// Update the tabs based on the third condition
		setTabs((prevTabs) => updateTabsByName(prevTabs, [TAB_NAMES.USER_JOURNEY], thirdCondition));

		// Check if current flight has selected touchpoint and persona
		const fourthCondition =
			currentVersion.selectedTouchpoint?.persona === currentVersion.selectedPersona && currentVersion.selectedTouchpoint?.persona !== undefined;

		// Update the tabs based on the fourth condition
		setTabs((prevTabs) => updateTabsByName(prevTabs, [TAB_NAMES.JOB_TO_BE_DONE], fourthCondition));

		// Check if current flight has job to be done and pains and gains
		const fifthCondition = currentVersion.jobToBeDone && currentVersion.painsAndGains;

		// Update the tabs based on the fifth condition
		setTabs((prevTabs) => updateTabsByName(prevTabs, [TAB_NAMES.CHALLENGES], fifthCondition));

		// Check if current flight has inspirations
		const sixthCondition = currentVersion.inspirationTexts ? Object.values(currentVersion.inspirationTexts).length > 0 : false;

		// Update the tabs based on the sixth condition
		setTabs((prevTabs) => updateTabsByName(prevTabs, [TAB_NAMES.IDEATION], sixthCondition));

		// Check if current flight has user ideas and selected idea
		const seventhCondition = currentVersion.ideas && currentVersion.selectedIdea;

		// Update the tabs based on the seventh condition
		setTabs((prevTabs) => updateTabsByName(prevTabs, [TAB_NAMES.CONCEPT_POSTER], seventhCondition));
	}, [
		hasSubmittedAnswers,
		hasSubmittedSummary,
		hasSubmittedDesignChallenge,
		hasSubmittedMainProblems,
		hasSubmittedGoodPractices,
		hasSubmittedPointsOfView,
		hasSubmittedUserJourney,
		isUserJourneyLoading,

		currentVersion,
		userFlights,
		currentFlight,
		activeTabIndex,
		currentFlight?.selectedTouchpoint,
	]);

	useEffect(() => {
		if (isDemoUser) {
			addNewFlight();
		}
	}, [isDemoUser]);

	return (
		<div className="bg-linear-gradient">
			{/* LOADER */}
			<BounceLoaderPlum isLoading={loading} />

			{/* ALERT */}
			<Alert alert={alert} />

			{/* MODAL */}
			{isModalVisible && <ShareLinkModal onClick={handleCloseModal} modalContent={modalContent} />}

			{/* NAVIGATION */}
			<Navigation />

			{/* MAIN CONTENT */}
			<div className="flight-container">
				{!isDemoUser && <FlightList handleAddNew={handleAddNew} handleOpenModal={handleOpenModal} handleDelete={handleDelete} />}

				{currentFlight && <ActiveFlight tabs={tabs} />}
			</div>
		</div>
	);
}
