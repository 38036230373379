import React, { useState, useEffect } from "react";

import { generateImage, updateVersion } from "../../../services/flightService";
import { useIntersectionAnimation } from "../../../hooks";
import { useFlightContext } from "../../../contexts/FlightContext";
import { SmallLoader } from "../..";
import { BounceLoaderPlum } from "../..";

import "./personas.css";

export default function Personas() {
	const {
		editSingleFlight,
		fetchUserJourney,
		isUserJourneyLoading,
		updateCurrentFlight,
		currentVersion,
		setCurrentVersion,
		activeTabIndex,
		prevTabIndex,
	} = useFlightContext();

	const [selectedPersona, setSelectedPersona] = useState(currentVersion.selectedPersona);
	const [isLoading, setIsLoading] = useState(true);

	async function handleClick(persona) {
		const updatedVersion = await updateVersion(currentVersion._id, {
			versionNumber: currentVersion.versionNumber,
			selectedPersona: persona,

			selectedTouchpoint: "",
			jobToBeDone: "",
			painsAndGains: "",
			userChallenge: "",
			userChallengesJSON: "",
			inspirationTexts: {},
			inspirationImages: {},
			ideas: [],
			selectedIdea: "",
			conceptPoster: "",
		});

		setCurrentVersion(updatedVersion);

		setSelectedPersona(persona);

		fetchUserJourney(persona);
	}

	// Determine the animation class based on the tab index
	const animationClass = prevTabIndex < activeTabIndex ? "slide-in-from-right" : "slide-in-from-left";

	// Call the useIntersectionAnimation hook within the component
	useIntersectionAnimation(".tab-container", animationClass);

	useEffect(() => {
		const allPersonasHaveUrl = Object.values(currentVersion.personas).some((persona) => persona.url !== undefined);

		if (allPersonasHaveUrl) {
			setIsLoading(false);
		} else {
			console.log("Not all personas have a url property.");
		}
	}, [currentVersion]);

	return (
		<>
			<BounceLoaderPlum isLoading={isUserJourneyLoading} message={isLoading ? "Generating Personas..." : "Generating User Journey..."} />

			<div id="points-of-view" className="tab-container">
				{currentVersion.personas ? (
					<div className="user-cards-container">
						{Object.keys(currentVersion.personas).map((persona, index) => (
							<div key={index} className={`user-card ${selectedPersona === persona && "user-card-selected"}`}>
								<div className="user-card-top">
									{!isLoading ? (
										<img
											src={`data:image/png;base64,${currentVersion.personas[persona].url}`}
											className="user-avatar"
											alt="img"
										/>
									) : (
										<SmallLoader style={{ margin: "auto" }} />
									)}

									<h4 className="user-name">
										{persona.split(":")[1]} {currentVersion.personas[persona].name}
									</h4>
								</div>
								<div className="user-card-bottom">
									<p className="user-info user-need">
										<b>Need: </b>
										{currentVersion.personas[persona].need}
									</p>
									<p className="user-info user-insight">
										<b>Insight: </b>
										{currentVersion.personas[persona].insight}
									</p>
								</div>
								<button
									className={`user-card-action ${(isLoading || isUserJourneyLoading) && "user-card-action-disabled"}`}
									disabled={isLoading || isUserJourneyLoading || selectedPersona === persona}
									onClick={() => handleClick(persona)}>
									{isLoading || isUserJourneyLoading ? (
										<SmallLoader style={{ margin: "auto" }} />
									) : selectedPersona === persona ? (
										"Selected"
									) : (
										"Get User Journey"
									)}
								</button>
							</div>
						))}
					</div>
				) : (
					<div>
						<h2>Personas</h2>
						<SmallLoader />
					</div>
				)}
			</div>
		</>
	);
}
