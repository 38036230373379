import { useEffect, useRef } from "react";

export const useIntersectionAnimation = (className, animationClass, options = { root: null, rootMargin: "0px", threshold: 1 }) => {
	const hasAnimatedRef = useRef(false);

	useEffect(() => {
		if (hasAnimatedRef.current) return;

		const startAnimation = (entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add(animationClass);
					observer.unobserve(entry.target);
					hasAnimatedRef.current = true;
				}
			});
		};

		const observer = new IntersectionObserver(startAnimation, options);
		const elements = document.querySelectorAll(className);

		elements.forEach((el) => {
			observer.observe(el);
		});

		// Cleanup on unmount
		return () => {
			elements.forEach((el) => {
				observer.unobserve(el);
			});
		};
	}, [className, animationClass, options]);
};
