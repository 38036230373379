import React from "react";
import loading from "../../assets/images/Loading_icon.gif";
import NoImage from "../../assets/images/no-image.png";

export default function Card({ title, text, img_url, isLoading }) {
	return (
		<div className="card-item">
			<h4 className="card-title">{title}:</h4>
			<div>
				{isLoading ? (
					<img className="card-img" src={loading} alt="loading..." />
				) : (
					img_url && (
						<img
							className="card-img"
							src={`data:image/png;base64,${img_url}`}
							alt="scenario img"
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src = NoImage;
							}}
						/>
					)
				)}
				<div className="card-text">{text ? <p>{text}</p> : <p>Loading...</p>}</div>
			</div>
		</div>
	);
}
