import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";

import { deleteFlight } from "../../services/flightService";
import { useFlightContext } from "../../contexts/FlightContext";
import { useAuthContext } from "../../contexts/AuthContext";

import Logo from "../../assets/images/logo.png";
import "./navigation.css";

export default function Navigation() {
	const location = useLocation();

	const { user, isDemoUser, logoutUser } = useAuthContext();
	const { addNewFlight, currentFlight } = useFlightContext();

	const handleClick = async () => {
		const confirm = window.confirm("Are you sure you want to try again? All your progress will be lost.");

		if (confirm) {
			await deleteFlight(currentFlight._id);

			addNewFlight();
		}
	};

	return (
		<div className="nav">
			<div className="nav__logo">
				<Link className="nav__link" to="/">
					<img src={Logo} alt="logo" className="nav__img" />
				</Link>

				<Link className="nav__link" to="/">
					Innovation Flightdeck
				</Link>
			</div>

			{user && !isDemoUser ? (
				<div className="nav__controls nav__controls--authenticated">
					<div className="nav__user">
						<p className="nav__user-email">{user.email}</p>

						<Link className="nav__link" to="/auth/profile">
							<FontAwesomeIcon icon={faCircleUser} className="nav__user-icon" />
						</Link>
					</div>
					<Link className="nav__link" to="#" onClick={logoutUser}>
						Logout
					</Link>
				</div>
			) : (
				<div className="nav__controls nav__controls--unauthenticated">
					{isDemoUser && location.pathname === "/innovation-flight-demo" && (
						<button className="btn btn-light" onClick={handleClick}>
							try again
						</button>
					)}

					<Link className="nav__link" to="/auth/login">
						Login
					</Link>
					<Link className="nav__link" to="/auth/register">
						Register
					</Link>
				</div>
			)}
		</div>
	);
}
