import { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceArea, LabelList } from "recharts";

import { updateVersion } from "../../../services/flightService";
import { useFlightContext } from "../../../contexts/FlightContext";
import { useIntersectionAnimation } from "../../../hooks";

import BounceLoaderPlum from "../../UI/Loader/BounceLoaderPlum";
import UserImg from "../../../assets/images/user.png";
import "./user-journey.css";

export default function UserJourney() {
	const {
		currentVersion,
		setHasSubmittedJobToBeDone,
		setHasSubmittedPainsAndGains,
		setCurrentVersion,
		setActiveTabIndex,
		prevTabIndex,
		activeTabIndex,
	} = useFlightContext();

	const [selectedPersona, setSelectedPersona] = useState(currentVersion.selectedPersona);
	const [persona, setPersona] = useState(currentVersion.personas[selectedPersona]);
	const [selectedTouchpoint, setSelectedTouchpoint] = useState(currentVersion.selectedTouchpoint);
	const [userJourney, setUserJourney] = useState(currentVersion.personas[selectedPersona].touchpoints);

	const [chartData, setChartData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [hoveredData, setHoveredData] = useState(null);

	const handleClick = async (e, payload) => {
		setIsLoading(true);

		const clickedTouchpointIndex = payload.index;
		const clickedTouchpoint = userJourney[`touchpoint${clickedTouchpointIndex + 1}`];

		if (clickedTouchpoint?.title !== currentVersion.selectedTouchpoint?.[currentVersion.selectedPersona]?.title) {
			const updatedVersion = await updateVersion(currentVersion._id, {
				versionNumber: currentVersion.versionNumber,
				selectedTouchpoint: {
					persona: currentVersion.selectedPersona,
					[`touchpoint${clickedTouchpointIndex}`]: clickedTouchpoint,
				},
				jobToBeDone: "",
				painsAndGains: "",
				userChallenge: "",
				userChallengesJSON: "",
				inspirationTexts: {},
				inspirationImages: {},
				ideas: [],
				selectedIdea: "",
				conceptPoster: "",
			});

			setCurrentVersion(updatedVersion);

			setSelectedTouchpoint({
				[selectedPersona]: clickedTouchpoint,
			});

			setHasSubmittedJobToBeDone(false);
			setHasSubmittedPainsAndGains(false);

			setTimeout(() => {
				setIsLoading(false);
				setActiveTabIndex(6);
			}, 2000);
		}
	};

	const handleMouseEnter = (data) => {
		setHoveredData(data);
	};

	const handleMouseLeave = () => {
		setHoveredData(null);
	};

	const animationClass = prevTabIndex < activeTabIndex ? "slide-in-from-right" : "slide-in-from-left";

	useIntersectionAnimation(".tab-container", animationClass);

	useEffect(() => {
		const data = Object.values(userJourney).map((touchpoint) => ({
			title: touchpoint.title,
			satisfactionLevel: touchpoint.satisfactionLevel,
			thoughts: touchpoint.thoughts,
			actions: touchpoint.actions,
		}));

		setChartData(data);
	}, [userJourney]);

	return (
		<>
			<BounceLoaderPlum isLoading={isLoading} message={"Generating Job to be Done and Pains & Gains..."} />

			<div className="tab-container">
				<div id="user-journey" style={{ display: "flex", backgroundColor: "white" }}>
					<div className="persona-container" style={{ backgroundColor: "#f4e9ed" }}>
						<img
							src={`data:image/png;base64,${currentVersion.personas[selectedPersona].url}`}
							className="user-avatar"
							alt="img"
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src = UserImg;
							}}
						/>
						<div className="persona-info">
							<p className="persona-title">
								<b>{persona.name}</b>
							</p>
							<p className="persona-touchpoint">
								<b>
									{selectedTouchpoint && Object.values(currentVersion.selectedTouchpoint)[0] === selectedPersona
										? Object.values(currentVersion.selectedTouchpoint)[1]?.title
										: "Select Touchpoint"}
								</b>
							</p>

							<p className="persona-need">
								<b>Need: </b>
								{persona.need}
							</p>
							<p className="persona-insight">
								<b>Insight: </b>
								{persona.insight}
							</p>
						</div>
					</div>

					<div style={{ width: "100%", height: "75vh" }}>
						<ResponsiveContainer width="100%" height="85%">
							<LineChart data={chartData} margin={{ top: 50, right: 50, left: 20, bottom: 0 }}>
								<CartesianGrid strokeDasharray="3 3" />

								<XAxis dataKey="title" tick={null} />
								<YAxis domain={[-5, 5]} ticks={[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]} />

								<ReferenceArea y1={-5} y2={-2} fill="var(--main-color-dark)" fillOpacity={0.3} />
								<ReferenceArea y1={2} y2={5} fill="#789d4a" fillOpacity={0.3} />

								<Line
									type="monotone"
									dataKey="satisfactionLevel"
									stroke="var(--main-color-dark)"
									strokeWidth={2}
									dot={customDot(selectedTouchpoint, handleMouseEnter, handleMouseLeave, handleClick)}>
									<LabelList dataKey="title" content={(props) => <CustomLabel {...props} />} />
								</Line>
							</LineChart>
						</ResponsiveContainer>

						<div style={{ textAlign: "center", marginTop: "-1rem" }}>
							{hoveredData ? (
								<>
									<p style={{ fontSize: "14px", marginBottom: "0.35rem", color: "#333" }}>
										<strong>Title:</strong> {hoveredData.title}
									</p>
									<p style={{ fontSize: "14px", marginBottom: "0.35rem", color: "#333" }}>
										<strong>Satisfaction Level:</strong> {hoveredData.satisfactionLevel}
									</p>
									<p style={{ fontSize: "14px", marginBottom: "0.35rem", color: "#333" }}>
										<strong>Thoughts:</strong> {hoveredData.thoughts}
									</p>
									<p style={{ fontSize: "14px", color: "#333" }}>
										<strong>Actions:</strong> {hoveredData.actions}
									</p>
								</>
							) : (
								<p style={{ fontSize: "14px", color: "#333" }}>Hover over a point to see details.</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

const customDot = (selectedTouchpoint, handleMouseEnter, handleMouseLeave, handleClick) => (props) => {
	const { cx, cy, payload } = props;

	const selectedTitle = Object.values(selectedTouchpoint)[1]?.title;

	const fillColor = payload.title === selectedTitle ? "var(--main-color-dark)" : "#ddc8d0";

	return (
		<circle
			cx={cx}
			cy={cy}
			r={7}
			stroke="none"
			fill={fillColor}
			onMouseEnter={() => handleMouseEnter(payload)}
			onMouseLeave={handleMouseLeave}
			onClick={(event) => handleClick(event, props)}
			style={{ cursor: "pointer" }}
		/>
	);
};

const CustomLabel = ({ x, y, value, index }) => {
	const fontSize = 10;
	const positionY = index % 2 === 0 ? y + 15 : y - 15;

	return (
		<text x={x} y={positionY} textAnchor="middle" fill="#000" fontSize={fontSize}>
			{value}
		</text>
	);
};
