import { useEffect, useState } from "react";

import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSave } from "@fortawesome/free-solid-svg-icons";

import { SolidLine } from "../../components";
import { useIntersectionAnimation } from "../../hooks";
import { useFlightContext } from "../../contexts/FlightContext";
import { askGPT, updateVersion, generateImage, createVersionAndAddToFlight } from "../../services/flightService";
import { BounceLoaderPlum } from "..";

import loading from "../../assets/images/Loading_icon.gif";
import NoImage from "../../assets/images/no-image.png";

import "./tabs.css";

const EditableField = ({ label, value, onSave, isEditing, onEdit }) => {
	const [newValue, setNewValue] = useState(value);

	const handleInputChange = (e) => {
		setNewValue(e.target.value);
	};

	return (
		<div style={{ display: "flex", width: "100%", gap: "1rem", alignItems: "center", marginBottom: "0.5rem" }}>
			<div style={{ width: "10%", minWidth: "150px" }}>
				<b>{label}:</b>
			</div>

			<div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
				{isEditing ? (
					<>
						<textarea
							value={newValue}
							onChange={handleInputChange}
							style={{
								fontSize: "1rem",
								padding: "0.2rem 0.5rem",
								border: "1px solid var(--main-color-dark)",
								borderRadius: "5px",
								background: "transparent",
								outline: "none",
								margin: 0,
								lineHeight: "1.5",
								flexGrow: 1,
								width: "100%",
								resize: "none",
								minHeight: "5rem",
							}}
						/>
					</>
				) : (
					<>
						<span
							style={{
								fontSize: "1rem",
								padding: "0.2rem 0.5rem",
								lineHeight: "1.5",
								flexGrow: 1,
								width: "100%",
							}}>
							{value}
						</span>
					</>
				)}
			</div>

			{isEditing ? (
				<button
					onClick={() => {
						onSave(newValue);
					}}
					className="control-btn">
					<FontAwesomeIcon
						icon={faSave}
						style={{ outline: "none" }}
						data-tooltip-id={`${label}-save-tooltip`}
						data-tooltip-content="Save"
					/>
					<Tooltip id={`${label}-save-tooltip`} />
				</button>
			) : (
				<button onClick={onEdit} className="control-btn">
					<FontAwesomeIcon icon={faPen} style={{ outline: "none" }} data-tooltip-id={`${label}-edit-tooltip`} data-tooltip-content="Edit" />
					<Tooltip id={`${label}-edit-tooltip`} />
				</button>
			)}
		</div>
	);
};

export default function ConceptPoster() {
	const { currentFlight, currentVersion, setCurrentVersion, updateCurrentFlight, prevTabIndex, activeTabIndex } = useFlightContext();

	const [selectedIdea, setSelectedIdea] = useState(currentVersion.selectedIdea || "");
	const [conceptPoster, setConceptPoster] = useState(currentVersion.conceptPoster ? JSON.parse(currentVersion.conceptPoster) : {});
	const [img_url, setImgUrl] = useState(currentVersion.conceptPosterImageURL ? currentVersion.conceptPosterImageURL : "");

	const [isLoading, setIsLoading] = useState(false);

	const [isEditingProductName, setIsEditingProductName] = useState(false);
	const [isEditingSolutionApproach, setIsEditingSolutionApproach] = useState(false);
	const [isEditingKeyBenefits, setIsEditingKeyBenefits] = useState(false);

	const handleSave = async (field, newValue) => {
		conceptPoster[field] = newValue;

		const updatedVersion = await updateVersion(currentVersion._id, {
			versionNumber: currentVersion.versionNumber,
			conceptPoster: JSON.stringify(conceptPoster),
		});

		setCurrentVersion(updatedVersion);

		switch (field) {
			case "productName":
				setIsEditingProductName(false);
				break;
			case "solutionApproach":
				setIsEditingSolutionApproach(false);
				break;
			case "keyBenefits":
				setIsEditingKeyBenefits(false);
				break;
			default:
				break;
		}
	};

	const handleClick = async () => {
		console.log("Generating concept poster...");

		setIsLoading(true);
		setImgUrl("");
		setConceptPoster({});

		const history = [
			{
				role: "user",
				content: `
					Persona Name: ${currentVersion.personas[currentVersion.selectedPersona].name};
					Persona Need: ${currentVersion.personas[currentVersion.selectedPersona].need};
					Persona Insight: ${currentVersion.personas[currentVersion.selectedPersona].insight}.`,
			},
		];

		const textPrompt = `Describe the selected idea "${selectedIdea}" as a concept poster. \
		Start by giving the idea a product name. Use the persona and the needs addressed to provide context. \
		Then, in no more than two sentences, describe the solution approach and highlight the key benefits it provides.
		
		Format your response strictly as a JSON object. Do not include any additional text, formatting, or markdown. Only include the JSON object, formatted exactly like this:
		
		{
			productName": "[Name of the product]",
			solutionApproach": "[Describe the solution approach]",
			keyBenefits: "[List the key benefits]"
		}`;

		const newConceptPoster = await askGPT(history, textPrompt);

		setConceptPoster(JSON.parse(newConceptPoster));

		const imagePrompt = await askGPT([], `Generate a prompt that helps DALLE-3 to create an image of the following idea ${selectedIdea}`);

		setIsLoading(false);

		const newImgUrl = await generateImage(`${imagePrompt}`, "dall-e-3", "1024x1024");
		setImgUrl(newImgUrl);

		const { _id, ...restOfCurrentVersion } = currentVersion;

		const response = await createVersionAndAddToFlight(currentFlight._id, {
			...restOfCurrentVersion,
			versionNumber: currentFlight.versions.length + 1,
			conceptPoster: newConceptPoster,
			conceptPosterImageURL: newImgUrl,
		});

		setCurrentVersion(response.version);
		updateCurrentFlight(response.flight);
	};

	// Determine the animation class based on the tab index
	const animationClass = prevTabIndex < activeTabIndex ? "slide-in-from-right" : "slide-in-from-left";

	// Call the useIntersectionAnimation hook within the component
	useIntersectionAnimation(".tab-container", animationClass);

	useEffect(() => {
		if (Object.entries(conceptPoster).length === 0) {
			handleClick();
		}
	}, [currentVersion]);

	useEffect(() => {
		setSelectedIdea(currentVersion.selectedIdea);
		setConceptPoster(currentVersion.conceptPoster ? JSON.parse(currentVersion.conceptPoster) : {});
	}, [currentVersion]);

	return (
		<>
			<BounceLoaderPlum isLoading={isLoading} message={"Generating Concept Poster..."} />

			<div className="tab-container">
				<h2 className="tab-title">Your selected idea:</h2>
				<p>{selectedIdea}</p>

				<div className="btn-group">
					<button onClick={handleClick} className="btn btn-dark" disabled={isLoading}>
						Generate New Concept Poster
					</button>
				</div>

				<div>
					{Object.entries(conceptPoster).length > 0 && (
						<>
							{conceptPoster && (
								<img
									className="concept-poster-img"
									src={!img_url ? loading : `data:image/png;base64,${img_url}`}
									alt="scenario img"
									onError={({ currentTarget }) => {
										currentTarget.onerror = null;
										currentTarget.src = NoImage;
									}}
								/>
							)}

							<EditableField
								label="Product Name"
								value={conceptPoster.productName}
								isEditing={isEditingProductName}
								onEdit={() => setIsEditingProductName(true)}
								onSave={(newValue) => handleSave("productName", newValue)}
							/>

							<SolidLine isDark={true} />

							<EditableField
								label="Solution Approach"
								value={conceptPoster.solutionApproach}
								isEditing={isEditingSolutionApproach}
								onEdit={() => setIsEditingSolutionApproach(true)}
								onSave={(newValue) => handleSave("solutionApproach", newValue)}
							/>

							<SolidLine isDark={true} />

							<EditableField
								label="Key Benefits"
								value={conceptPoster.keyBenefits}
								isEditing={isEditingKeyBenefits}
								onEdit={() => setIsEditingKeyBenefits(true)}
								onSave={(newValue) => handleSave("keyBenefits", newValue)}
							/>
						</>
					)}
				</div>
			</div>
		</>
	);
}
