import { useEffect, useState } from "react";

import { updateVersion } from "../../services/flightService";
import { useFlightContext } from "../../contexts/FlightContext";
import { SolidLine, Regenerate, BounceLoaderPlum, EditableField } from "..";
import { Error } from "../../pages";
import { useIntersectionAnimation } from "../../hooks";
import { randomId } from "../../utils/helpers";

import "./tabs.css";

export default function Ideation() {
	const { currentVersion, setCurrentVersion, setSelectedIdea, selectedIdea, setActiveTabIndex, prevTabIndex, activeTabIndex } = useFlightContext();

	const id = randomId();

	const [ideas, setIdeas] = useState([]);

	const [isLoading, setIsLoading] = useState(false);
	const [isEditing, setIsEditing] = useState({});
	const [isError, setIsError] = useState(false);

	const handleSelect = async (idea) => {
		try {
			setSelectedIdea(idea);

			const updatedVersion = await updateVersion(currentVersion._id, {
				versionNumber: currentVersion.versionNumber,
				selectedIdea: idea,
				conceptPoster: "",
				conceptPosterImageURL: "",
			});

			setCurrentVersion(updatedVersion);

			setActiveTabIndex(9);
		} catch (error) {
			setIsError(true);
		}
	};

	const handleEdit = (index) => {
		setIsEditing((prev) => ({ ...prev, [index]: true }));
	};

	const handleSave = async (index, newValue) => {
		setIdeas((prev) => {
			const updatedIdeas = [...prev];
			updatedIdeas[index] = newValue;
			return updatedIdeas;
		});

		const updatedIdeas = ideas.map((idea, i) => (i === index ? newValue : idea));

		const updatedVersion = await updateVersion(currentVersion._id, {
			versionNumber: currentVersion.versionNumber,
			ideas: JSON.stringify(updatedIdeas),
		});

		setCurrentVersion(updatedVersion);

		setIsEditing((prev) => ({ ...prev, [index]: false }));
	};

	// Determine the animation class based on the tab index
	const animationClass = prevTabIndex < activeTabIndex ? "slide-in-from-right" : "slide-in-from-left";

	// Call the useIntersectionAnimation hook within the component
	useIntersectionAnimation(".tab-container", animationClass);

	useEffect(() => {
		if (currentVersion.ideas.length > 0) {
			setIsLoading(false);
			setIdeas(JSON.parse(currentVersion.ideas));
		} else {
			setIsLoading(true);
			setIdeas([]);
		}
	}, [currentVersion.ideas]);

	useEffect(() => {
		setSelectedIdea(currentVersion.selectedIdea);
	}, [currentVersion.selectedIdea]);

	if (isError) {
		return <Error error={isError} />;
	}

	return (
		<>
			{ideas.length === 0 && <BounceLoaderPlum isLoading={isLoading} message={"Generating Ideas..."} />}

			<div className="tab-container">
				<h2 className="tab-title">Ideation</h2>

				{ideas.length > 0 && (
					<>
						{ideas.map((idea, index) => (
							<div key={index} className="selectable-titles-container">
								<EditableField
									index={index}
									fieldValue={idea}
									selectedFieldValue={selectedIdea}
									onSave={(newValue) => handleSave(index, newValue)}
									isEditing={isEditing[index] || false}
									onEdit={() => handleEdit(index)}
									handleSelect={handleSelect}
								/>
							</div>
						))}

						<SolidLine isDark={true} />

						<Regenerate id={id} title={"Ideas"} />
					</>
				)}
			</div>
		</>
	);
}
