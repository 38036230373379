import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { logout, getCurrentUser } from "../services/authService";
import { DEMO_LOGIN } from "../assets/feedData";
const AppContext = React.createContext();

const AuthContextProvider = ({ children }) => {
	const navigate = useNavigate();

	const [user, setUser] = useState(null);
	const [isDemoUser, setIsDemoUser] = useState(false);

	const [isLoading, setIsLoading] = useState(true);

	const saveUser = (user) => {
		if (user.email === DEMO_LOGIN.EMAIL) {
			setIsDemoUser(true);
		} else {
			setIsDemoUser(false);
		}

		setUser(user);
	};

	const removeUser = () => {
		setUser(null);
		setIsDemoUser(false);
	};

	const fetchUser = async () => {
		try {
			const response = await getCurrentUser();

			if (response.status === 200) {
				const user = await response.json();
				saveUser(user.user);
			} else {
				const message = await response.json();
				console.log(message);
			}
		} catch (error) {
			removeUser();
		}
		setIsLoading(false);
	};

	const logoutUser = async () => {
		try {
			await logout();
			removeUser();
			navigate("/");
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchUser();
	}, []);

	return (
		<AppContext.Provider
			value={{
				isLoading,
				user,
				saveUser,
				logoutUser,
				isDemoUser,
				setIsDemoUser,
			}}>
			{children}
		</AppContext.Provider>
	);
};

export const useAuthContext = () => {
	return useContext(AppContext);
};

export { AuthContextProvider };
