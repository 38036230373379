import { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { createVersionAndAddToFlight } from "../../services/flightService";
import { useFlightContext } from "../../contexts/FlightContext";

import { Regenerate, TextToSpeech, EditText, SolidLine, SmallLoader } from "../../components";
import { createMarkup, cleanUpHtml, decodeHtmlEntities, formatContent, randomId } from "../../utils/helpers";

import "../tabs/tabs.css";

const TabSection = ({ title, content }) => {
	const id = randomId();
	const {
		currentFlight,
		currentVersion,
		updateCurrentFlight,
		setTriggersToFalse,
		setHasSubmittedAnswers,
		setHasSubmittedSummary,
		setHasSubmittedDesignChallenge,
		setHasSubmittedMainProblems,
		setHasSubmittedGoodPractices,
		setHasSubmittedPointsOfView,
		setHasSubmittedJobToBeDone,
		setHasSubmittedPainsAndGains,
		setCurrentVersion,
	} = useFlightContext();

	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [convertedContent, setConvertedContent] = useState("");

	const [isEditing, setIsEditing] = useState(false);
	const [tag, setTag] = useState("");

	const handleTabChange = (title) => {
		switch (title) {
			case "Summary":
				setTag("summary");
				break;
			case "Design Challenge":
				setTag("designChallenge");
				break;
			case "Main Problems":
				setTag("mainProblems");
				break;
			case "Good Practices":
				setTag("goodPractices");
				break;
			case "Job to be Done":
				setTag("jobToBeDone");
				break;
			case "Pains & Gains":
				setTag("painsAndGains");
				break;
			default:
				setTag("");
		}
	};

	useEffect(() => {
		handleTabChange(title);
	}, [title]);

	useEffect(() => {
		if (content) {
			const formattedContent = formatContent(content);

			const contentState = stateFromHTML(formattedContent);
			setEditorState(EditorState.createWithContent(contentState));
		}
	}, [content]);

	useEffect(() => {
		if (editorState) {
			const html = convertToHTML(editorState.getCurrentContent());
			const cleanedHtml = cleanUpHtml(decodeHtmlEntities(html));
			setConvertedContent(cleanedHtml);
		}
	}, [editorState]);

	const handleEdit = () => {
		setIsEditing(true);
	};

	const handleSave = async (title) => {
		setTriggersToFalse();

		let response;

		switch (title) {
			case "Summary":
				response = await createVersionAndAddToFlight(currentFlight._id, {
					versionNumber: currentFlight.versions.length + 1,
					answers: currentVersion.answers,
					designChallenge: currentVersion.designChallenge,
					[tag]: convertedContent,
				});

				setHasSubmittedAnswers(true);
				setHasSubmittedSummary(true);
				setHasSubmittedDesignChallenge(true);
				break;

			case "Design Challenge":
				response = await createVersionAndAddToFlight(currentFlight._id, {
					versionNumber: currentFlight.versions.length + 1,
					answers: currentVersion.answers,
					summary: currentVersion.summary,
					[tag]: convertedContent,
				});

				setHasSubmittedAnswers(true);
				setHasSubmittedSummary(true);
				setHasSubmittedDesignChallenge(true);
				break;

			case "Main Problems":
				response = await createVersionAndAddToFlight(currentFlight._id, {
					versionNumber: currentFlight.versions.length + 1,
					answers: currentVersion.answers,
					summary: currentVersion.summary,
					designChallenge: currentVersion.designChallenge,
					[tag]: convertedContent,
				});

				setHasSubmittedAnswers(true);
				setHasSubmittedSummary(true);
				setHasSubmittedDesignChallenge(true);
				setHasSubmittedMainProblems(true);
				break;

			case "Good Practices":
				response = await createVersionAndAddToFlight(currentFlight._id, {
					versionNumber: currentFlight.versions.length + 1,
					answers: currentVersion.answers,
					summary: currentVersion.summary,
					designChallenge: currentVersion.designChallenge,
					mainProblems: currentVersion.mainProblems,
					personas: currentVersion.personas,
					[tag]: convertedContent,
				});

				setHasSubmittedAnswers(true);
				setHasSubmittedSummary(true);
				setHasSubmittedDesignChallenge(true);
				setHasSubmittedMainProblems(true);
				setHasSubmittedGoodPractices(true);
				setHasSubmittedPointsOfView(true);
				break;

			case "Job to be Done":
				response = await createVersionAndAddToFlight(currentFlight._id, {
					versionNumber: currentFlight.versions.length + 1,
					answers: currentVersion.answers,
					summary: currentVersion.summary,
					designChallenge: currentVersion.designChallenge,
					mainProblems: currentVersion.mainProblems,
					goodPractices: currentVersion.goodPractices,
					personas: currentVersion.personas,
					selectedPersona: currentVersion.selectedPersona,
					selectedTouchpoint: currentVersion.selectedTouchpoint,
					[tag]: convertedContent,
				});

				setHasSubmittedAnswers(true);
				setHasSubmittedSummary(true);
				setHasSubmittedDesignChallenge(true);
				setHasSubmittedMainProblems(true);
				setHasSubmittedGoodPractices(true);
				setHasSubmittedPointsOfView(true);
				setHasSubmittedJobToBeDone(true);
				break;

			case "Pains & Gains":
				response = await createVersionAndAddToFlight(currentFlight._id, {
					versionNumber: currentFlight.versions.length + 1,
					answers: currentVersion.answers,
					summary: currentVersion.summary,
					designChallenge: currentVersion.designChallenge,
					mainProblems: currentVersion.mainProblems,
					goodPractices: currentVersion.goodPractices,
					personas: currentVersion.personas,
					selectedPersona: currentVersion.selectedPersona,
					selectedTouchpoint: currentVersion.selectedTouchpoint,
					jobToBeDone: currentVersion.jobToBeDone,
					[tag]: convertedContent,
				});

				setHasSubmittedAnswers(true);
				setHasSubmittedSummary(true);
				setHasSubmittedDesignChallenge(true);
				setHasSubmittedMainProblems(true);
				setHasSubmittedGoodPractices(true);
				setHasSubmittedPointsOfView(true);
				setHasSubmittedJobToBeDone(true);
				setHasSubmittedPainsAndGains(true);
				break;

			default:
				console.log("Saving...", title);
				console.log("Current version:", currentVersion);
				setIsEditing(false);

				return;
		}

		setCurrentVersion(response.version);
		updateCurrentFlight(response.flight);

		setIsEditing(false);
	};

	return (
		<div style={{ marginBottom: "2rem" }}>
			<h2 className="tab-title">{title}</h2>

			{content ? (
				<>
					{isEditing ? (
						<Editor
							editorState={editorState}
							onEditorStateChange={setEditorState}
							toolbarClassName="toolbar-class"
							toolbar={{
								options: ["inline", "blockType"],
							}}
						/>
					) : (
						<div id={title} dangerouslySetInnerHTML={createMarkup(convertedContent)} />
					)}
					<SolidLine isDark={true} />
					<div style={{ display: "flex" }}>
						<TextToSpeech id={id} text={editorState.getCurrentContent().getPlainText()} />
						<Regenerate id={id} title={title} />
						<EditText
							id={id}
							title={title}
							content={createMarkup(convertedContent)}
							isEditing={isEditing}
							onEdit={handleEdit}
							onSave={() => handleSave(title)}
						/>
					</div>
				</>
			) : (
				<SmallLoader />
			)}
		</div>
	);
};

export default TabSection;
