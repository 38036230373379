import React, { useState, useEffect } from "react";

// External Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { Page, Text, View, Document, Image, StyleSheet, PDFDownloadLink } from "@react-pdf/renderer";

// Context
import { useAuthContext } from "../../contexts/AuthContext";
import { useFlightContext } from "../../contexts/FlightContext";
import { useAppContext } from "../../contexts/AppContext";

const ActiveFlight = ({ tabs }) => {
	const { isDemoUser } = useAuthContext();
	const { activeTabIndex, setActiveTabIndex, currentVersion } = useFlightContext();
	const { isSidebarVisible, handleSidebar } = useAppContext();

	const [persona, setPersona] = useState({});
	const [selectedTouchpoint, setSelectedTouchpoint] = useState({});
	const [conceptPoster, setConceptPoster] = useState({});
	const [conceptPosterImageUrl, setConceptPosterImageUrl] = useState("");
	const [jobToBeDone, setJobToBeDone] = useState(() => currentVersion?.jobToBeDone);

	const icon = isSidebarVisible ? faChevronLeft : faChevronRight;
	const tooltipId = isSidebarVisible ? "collapse-tooltip" : "expand-tooltip";
	const tooltipContent = isSidebarVisible ? "Close Sidebar" : "Open Sidebar";

	const handleTabClick = (index) => {
		setActiveTabIndex(index);
	};

	useEffect(() => {
		if (currentVersion) {
			const selectedPersona = currentVersion?.personas?.[currentVersion.selectedPersona];
			setPersona(selectedPersona ? selectedPersona : {});

			if (currentVersion.conceptPoster) {
				try {
					setConceptPoster(JSON.parse(currentVersion.conceptPoster));
				} catch (error) {
					console.error("Error parsing conceptPoster:", error);
					setConceptPoster({});
				}
			} else {
				setConceptPoster({});
			}

			const touchpoint = currentVersion?.selectedTouchpoint;
			if (touchpoint && typeof touchpoint === "object") {
				const keys = Object.keys(touchpoint);
				const secondKey = keys[1];
				setSelectedTouchpoint(secondKey ? { key: secondKey, ...touchpoint[secondKey] } : {});
			}

			setConceptPosterImageUrl(currentVersion?.conceptPosterImageURL ? currentVersion.conceptPosterImageURL : "");

			setJobToBeDone(currentVersion?.jobToBeDone ? currentVersion.jobToBeDone : "");
		}
	}, [currentVersion]);

	// Define the styles for the PDF
	const styles = StyleSheet.create({
		page: {
			flexDirection: "column",
			padding: 20,
		},
		section: {
			margin: 7,
			lineHeight: 1.25,
			fontSize: 12,
		},
		title: {
			fontSize: 28,
			textAlign: "center",
		},
		subTitle: {
			fontSize: 14,
			fontWeight: "bold",
			marginBottom: 5,
		},
		avatar: {
			width: 100,
			height: 100,
			marginRight: 10,
			borderRadius: "10%",
		},
		personaContainer: {
			flexDirection: "row",
			flexWrap: "wrap",
		},
		personaText: {
			marginLeft: 10,
			flex: 1,
		},
		imageRow: {
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
		},
		leftImageContainer: {
			width: "50%",
			paddingRight: 10,
		},
		leftImage: {
			width: "50%",
			height: "auto",
			borderRadius: 10,
			marginBottom: 5,
			alignSelf: "center",
		},
		rightImage: {
			width: "50%",
			height: "auto",
			borderRadius: 10,
		},
	});

	const ConceptPosterPdf = () => (
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.section}>
					<Text style={styles.title}>{conceptPoster?.productName}</Text>
				</View>

				<View style={[styles.section, styles.imageRow]}>
					<View style={styles.leftImageContainer}>
						<Image style={styles.leftImage} src={`data:image/png;base64,${persona.url}`} />

						<Text style={{ marginBottom: 2 }}>Persona: {persona.name}</Text>
						<Text style={{ marginBottom: 2 }}>Need: {persona.need}</Text>
						<Text style={{ marginBottom: 2 }}>Insight: {persona.insight}</Text>
					</View>
					<Image style={styles.rightImage} src={`data:image/png;base64,${conceptPosterImageUrl}`} />
				</View>

				<View style={styles.section}>
					<Text style={styles.subTitle}>Context:</Text>

					<View style={{ marginBottom: 5 }}>
						<Text>Title: {selectedTouchpoint.title}</Text>
					</View>
					<View style={{ marginBottom: 5 }}>
						<Text>Thoughts: {selectedTouchpoint.thoughts}</Text>
					</View>
					<View style={{ marginBottom: 5 }}>
						<Text>Actions: {selectedTouchpoint.actions}</Text>
					</View>
					<View>
						<Text>Job to be Done: {jobToBeDone}</Text>
					</View>
				</View>

				<View style={styles.section}>
					<Text style={styles.subTitle}>Solution Approach:</Text>
					<Text>{conceptPoster?.solutionApproach}</Text>
				</View>

				<View style={styles.section}>
					<Text style={styles.subTitle}>Key Benefits:</Text>
					<Text>{conceptPoster?.keyBenefits}</Text>
				</View>
			</Page>
		</Document>
	);

	return (
		<>
			{!isDemoUser && (
				<div className="sidebar-control" onClick={handleSidebar}>
					<FontAwesomeIcon data-tooltip-id={tooltipId} data-tooltip-content={tooltipContent} icon={icon} className="chevron" />
					<Tooltip id={tooltipId} />
				</div>
			)}

			<div className={isSidebarVisible && !isDemoUser ? "flight-active" : "flight-active-fullscreen"}>
				<div className="tabs-container">
					{tabs.map((tab, index) => (
						<div
							key={index}
							onClick={() => {
								handleTabClick(index);
								tab.onClick && tab.onClick();
							}}
							className={`tab ${activeTabIndex === index ? "tab--active" : ""} ${tab.enabled ? "" : "tab--disabled"}`}>
							{tab.title}
						</div>
					))}

					{currentVersion?.conceptPoster && (
						<PDFDownloadLink document={<ConceptPosterPdf />} fileName="concept_poster.pdf">
							{({ blob, url, loading, error }) => (
								<>
									<FontAwesomeIcon
										icon={faFilePdf}
										style={{
											fontSize: "32px",
											color: "var(--main-color-dark)",
											cursor: "pointer",
										}}
									/>
								</>
							)}
						</PDFDownloadLink>
					)}
				</div>

				{/* Render the content of the active tab */}
				{React.cloneElement(tabs[activeTabIndex].content, {})}
			</div>
		</>
	);
};

export default ActiveFlight;
