export const QUESTIONS = {
	QUESTION_1: "What challenge do you want to address?",
	QUESTION_2: "In what context does this topic arise?",
	QUESTION_3: "What positive changes should occur when you have solved the challenge?",
	QUESTION_4: "Who is primarily affected by the topic?",
	QUESTION_5: "Is there anything else we should consider about the topic?",
};

export const PLACEHOLDERS = {
	PLACEHOLDER_1: "e.g. time lost at the airport",
	PLACEHOLDER_2: "e.g. waiting at the airport to get through security check",
	PLACEHOLDER_3: "e.g. reduce time for waiting substantially",
	PLACEHOLDER_4: "e.g. passengers",
	PLACEHOLDER_5: "e.g. security check's quality should not be affected",
};

export const TAB_NAMES = {
	START: "Start",
	SUMMARY: "Summary",
	DESIGN_CHALLENGE: "Design Challenge",
	MAIN_PROBLEMS: "Main Problems",
	GOOD_PRACTICES: "Good Practices",
	PERSONAS: "Personas",
	USER_JOURNEY: "User Journey",
	JOB_TO_BE_DONE: "Job to be Done",
	CHALLENGES: "Challenges",
	INSPIRATIONS: "Inspirations",
	IDEATION: "Ideation",
	CONCEPT_POSTER: "Concept Poster",
};

export const SCENARIO_TITLES = {
	FIRST_SCENARIO: "The Animal Kingdom",
	SECOND_SCENARIO: "Earlier 1000 Years",
	THIRD_SCENARIO: "Far Away from Europe",
	FOURTH_SCENARIO: "The World of Fiction",
};

export const MAIN_TASK = "Reformulate the following question by generalizing subject, objects, and activities while keeping all adjectives";

export const TERMS_TITLE = "Terms and Limitations";
export const TERMS_TEXT = [
	"Innovation Flightdeck utilizes a large language model to assist you in addressing your design challenges. While such models can be powerful tools, they may not always provide accurate solutions. For matters of sensitivity or importance, we strongly recommend contacting our support team, where a human expert will be available to assist you.",
	"When you submit a request, both the request itself and its contextual data are transmitted to our partner, OpenAI. However, personally identifiable information, such as your email address, is not shared as part of this process.",
];

export const DEMO_LOGIN = {
	EMAIL: "test@launchlabs.de",
	PASSWORD: "s516OnG68aA0TDQ",
};
